import React, { useState, useEffect, useContext } from 'react'
import { useSignal } from "@preact/signals-react"
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputSvgGroup.scss'
import SvgButton from "../SvgButton/SvgButton"

const InputSvgGroup = ({ layerKey, input, setValue, useValue, inputRequired }) => {
    const { state } = useContext(StoreContext)
    const [selectedSvgButton, setSelectedSvgButton] = useState(useValue)
    const [initialEffect, setInitialEffect] = useState(true)
    const [dupedInput, setDupedInput] = useState(input)
    const [savedInput, setSavedInput] = useState(null)
    const signalLargeButtons = useSignal(false)
    const signalHoverText = useSignal('')
    const signalSelectedButtonName = useSignal('')
    const svgsOpen = useSignal(true)

    useEffect(() => {
        // watches for changes of svgName
        if (useValue) {
            let svgObj = state.svgs.find(ssvg => ssvg.svgKey === useValue)
            if (svgObj) {
                signalSelectedButtonName.value = svgObj.svgName
            }
        }
    }, [state.svgs])

    useEffect(() => {
        // we need to ensure any new svg buttons coming in due to a update get displayed.
        // Therefore, we need to remove the existing buttons, and redraw them.
        setDupedInput({ list: [] })
        setSavedInput(JSON.parse(JSON.stringify(input)))
        let originalLayerName = Utility.originalLayerName(state.layers, layerKey)
        if (originalLayerName === 'custom svgs' || originalLayerName === 'custom images') {
            signalLargeButtons.value = true
        }
    }, [input])

    useEffect(() => {
        if (savedInput !== null) {
            // remove any dupe svgKeys
            const _list = savedInput.list.reduce((accumulator, current) => {
                if (!accumulator.find((item) => item.svgKey !== current.svgKey)) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
            let _savedInput = JSON.parse(JSON.stringify(savedInput))
            _savedInput.list = _list
            setDupedInput(_savedInput)
            setSavedInput(null)
        }
    }, [savedInput])

    useEffect(() => {
        if (initialEffect) {
            setInitialEffect(false)
            buttonNameFromSelected()
            return
        }
        buttonNameFromSelected()
        setValue(input.inputKey, selectedSvgButton)
    }, [selectedSvgButton]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (selectedSvgButton !== useValue) {
                setSelectedSvgButton(useValue)
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectButton = (svgKey) => {
        if (svgKey === selectedSvgButton) {
            setSelectedSvgButton(null) // null is for 'none'
            signalSelectedButtonName.value = null
        }
        else {
            setSelectedSvgButton(svgKey)
            signalSelectedButtonName.value = signalHoverText.value
        }
    }

    const delaySelectButton = (svgKey) => {
        if (svgKey !== null && svgKey !== undefined) {
            selectButton(svgKey)
        }
    }

    const hoveringOn = svgName => {
        signalHoverText.value = svgName
    }

    const buttonNameFromSelected = () => {
        if (state.svgs) {
            let foundSvg = state.svgs.find(svg => svg.svgKey === selectedSvgButton)
            if (foundSvg) {
                let svgName = foundSvg.svgName
                let shortenedName = svgName.replace('misc unit symbol ', '')
                shortenedName = shortenedName.replace('nato symbol ', '')
                shortenedName = shortenedName.replace('common symbol ', '')
                shortenedName = shortenedName.replace('common symbols ', '')
                shortenedName = shortenedName.replace('ww2 vehicles ', '')
                signalSelectedButtonName.value = shortenedName
            }
        }
    }

    const svgsOpenChange = () => {
        svgsOpen.value = !svgsOpen.value
    }

    return (
        <div id={`input_svg_group_${input.inputKey}`} className="default-input-container input-svg-group">

            <div className="named-flex">

                <div className="named">{
                    selectedSvgButton ? signalSelectedButtonName.v :
                        input.message.replace('png', 'image')
                }</div>

                <div className={`${svgsOpen.value ? 'svgs-display opened-display' : 'svgs-display closed-display'}`}
                    onClick={svgsOpenChange}>
                    <span className="arrow-text">
                        {`${svgsOpen.value ? 'opened' : 'closed'}`
                        }
                    </span>
                    {`${svgsOpen.value ? '▾' : '▸'}`
                    }

                </div>

            </div>

            <div className={`${svgsOpen.value ? 'svg-icons-list' : 'svg-icons-list closed'}`}>
                <div className={signalLargeButtons.v ? 'svg-buttons large' : 'svg-buttons'}>
                    {dupedInput.list.map(svgKey => <SvgButton key={svgKey}
                        svgKey={svgKey}
                        selectButton={delaySelectButton}
                        selected={selectedSvgButton === svgKey}
                        largeButton={signalLargeButtons.v}
                        layerKey={layerKey}
                        hoveringOn={hoveringOn}
                    />)}
                </div>
            </div>

        </div>
    )
}

export default InputSvgGroup