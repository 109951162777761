import React, { useContext, useEffect, useState, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import BaseCounter from "../../drawingObjects/BaseCounter"
import NatoUnitSymbols from "../../drawingObjects/NatoUnitSymbols"
import UnitSize from "../../drawingObjects/UnitSize"
import MiscUnit from "../../drawingObjects/MiscUnit"
import CommonSymbols from "../../drawingObjects/CommonSymbols"
import MaterialSymbols from "../../drawingObjects/MaterialSymbols"
import CombatMovement from "../../drawingObjects/CombatMovement"
import Text from "../../drawingObjects/Text"
import Line from "../../drawingObjects/Line"
import Rectangle from "../../drawingObjects/Rectangle"
import Ellipse from "../../drawingObjects/Ellipse"
import Triangle from "../../drawingObjects/Triangle"
import Hexagon from "../../drawingObjects/Hexagon"
import IdentifierText from "../../drawingObjects/IdentifierText.js"
import Ww2Vehicles from "../../drawingObjects/Ww2Vehicles"
import CustomSvgs from "../../drawingObjects/CustomSvgs"
import { useSignal } from "@preact/signals-react"
import './DrawLayer.scss'

const DrawLayer = ({ layer, dragUpdate, svgReference }) => {
    const { state, actions } = useContext(StoreContext)
    const signalInputKeysValuesPreviousJson = useSignal('')
    const drawWidthRef = useRef(state.counterDrawWidth)
    const signalSavedLayerOrder = useSignal(-1)
    const signalOverlayActive = useSignal(false)
    const [paper, setPaper] = useState(null)
    const [initiateDraw, setInitiateDraw] = useState(false)
    const signalLineDragType = useSignal(null)

    useEffect(() => {
        signalOverlayActive.v = state.overlay
    }, [state.overlay])

    useEffect(() => {
        let _paper = Snap('#svgLayer' + layer.layerKey);
        if (_paper) {
            setPaper(_paper)
            setInitiateDraw(true)
        }
        else {
            console.error('could not create paper on svgLayer' + layer.layerKey)
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (initiateDraw) {
            if (Utility.layerIsActive(layer.layerKey, state.activeLayerValues)) {
                signalSavedLayerOrder.value = layer.layerOrder
            }
            return () => {
                if (paper) {
                    paper.clear()
                }
            }

        }
    }, [initiateDraw])

    useEffect(() => {
        if (paper) {
            topViewBoxSetting()
            drawLayer()
            svgReference({ paper, layerKey: layer.layerKey })
        }
    }, [paper])

    const topViewBoxSetting = () => {
        let originalName = Utility.originalLayerName(state.layers, layer.layerKey)
        if (Utility.isCustomLayer(layer.layerKey, state.layers)
            || (Utility.originalLayerName(state.layers, layer.layerKey) === 'ww2 vehicles')
            || (Utility.originalLayerName(state.layers, layer.layerKey) === 'national flags')) {
            paper.attr({ viewBox: "0, 0, 240, 240" }); // the ww2 vehicles were done in a different viewbox assumption.
        }
        else {
            paper.attr({ viewBox: "-120, -120, 240, 240" });
        }
    }

    useEffect(() => {
        if (paper && layer) {
            drawLayer()
        }
    }, [state.activeLayerValues])

    const drawLayer = () => {
        if (!state.layers.find(sl => sl.layerKey === layer.layerKey)) {
            return;
        }
        let inputKeysValuesForLayer = Utility.inputKeysValuesForLayer(state.activeLayerValues, layer.layerKey)
        const inputValuesJson = JSON.stringify(inputKeysValuesForLayer)
        // also do redraw if layers have been reordered.
        if (inputValuesJson === signalInputKeysValuesPreviousJson.value) {
            return
        }
        signalInputKeysValuesPreviousJson.value = inputValuesJson
        if (paper) {
            paper.clear()
        }
        let settings = []
        for (const [inputKey] of Object.entries(inputKeysValuesForLayer)) {
            let foundInputData = layer.inputs.find(li => li.inputKey === parseInt(inputKey))
            if (foundInputData) {
                settings.push({
                    inputKey: parseInt(inputKey),
                    list: foundInputData.list,
                    message: foundInputData.message,
                    comment: foundInputData.comment,
                    name: foundInputData.named, // check
                    type: foundInputData.type,
                    value: foundInputData.type === 'fill' ? JSON.parse(inputKeysValuesForLayer[inputKey]) : inputKeysValuesForLayer[inputKey]
                })
            }
        }
        if (settings.length === 0) {
            return
        }

        let g = paper.group()

        let originalLayerName = Utility.originalLayerName(state.layers, layer.layerKey)
        if (originalLayerName) {
            switch (originalLayerName) {
                case 'base counter': BaseCounter.draw(g, settings); break;
                case 'NATO unit symbols': NatoUnitSymbols.draw(g, settings, state.svgs); break;
                case 'unit size': UnitSize.draw(g, settings, state.svgs); break;
                case 'misc unit symbols': MiscUnit.draw(g, settings, state.svgs); break;
                case 'common symbols': CommonSymbols.draw(g, settings, state.svgs); break;
                case 'Google material symbols': MaterialSymbols.draw(g, settings, state.svgs); break;
                case 'combat-movement': CombatMovement.draw(g, settings, state.svgs); break;
                case 'text': Text.draw(g, settings, state.svgs); break;
                case 'line': Line.draw(g, settings, signalLineDragType.v); break;
                case 'rectangle': Rectangle.draw(g, settings); break;
                case 'ellipse': Ellipse.draw(g, settings); break;
                case 'triangle': Triangle.draw(g, settings); break;
                case 'hexagon': Hexagon.draw(g, settings); break;
                case 'identifier text': IdentifierText.draw(g, settings); break;
                case 'ww2 vehicles': Ww2Vehicles.draw(g, settings, state.svgs); break;
                case 'custom svgs': CustomSvgs.draw(g, settings, state.svgs, paper); break;
                case 'custom images': CustomSvgs.draw(g, settings, state.svgs, paper); break;
                case 'national flags': CustomSvgs.draw(g, settings, state.svgs, paper); break;
                default: console.warn('missing draw option for', originalLayerName)
            }

            // setup the drag listener
            if (originalLayerName !== 'base counter') { // can't drag base counter
                g.attr({ pointerEvents: "visiblePainted" })
                setDragger(g)
            }
            topViewBoxSetting()
        }
        else {
            console.warn('got null for originalLayerName for layerKey', layer.layerKey)
        }
        setTimeout(() => {
            svgReference({ paper: paper, layerKey: layer.layerKey })
        }, 300)
    }

    useEffect(() => {
        drawWidthRef.current = state.counterDrawWidth
    }, [state.counterDrawWidth])

    const setDragger = g => {
        let { x, y } = [0, 0]
        let { cx, cy } = [0, 0]
        let rightClicked = false

        const dragTracker = (active, dx, dy) => {
            if (signalOverlayActive.v === true) {
                return
            }
            if (dx === undefined || dy === undefined) {
                return
            }
            if (rightClicked) {
                return
            }
            reportDragged(dx, dy)
            if (!active) {

            }
            else {
                x = dx
                y = dy
            }
        }

        var move = function (dx, dy) {
            if (signalOverlayActive.v === true) {
                return
            }
            if (rightClicked) {
                return
            }
            g.attr({ cursor: "move" })
            // get width of counter drawing area, in pixels.
            let _counterDrawWidth = 0
            if (drawWidthRef.current > 0) {
                _counterDrawWidth = drawWidthRef.current
            }
            else {
                let ele = document.getElementById('counter')
                _counterDrawWidth = ele.offsetWidth;
                actions.counterDrawWidth(_counterDrawWidth)
            }

            if (_counterDrawWidth > 0) {
                let mul = 240 / _counterDrawWidth
                //mul = mul / 2
                dx *= mul; // need to modify it cause pixels for the mouse are not a direct match to pixels on the svg
                dy *= mul;
                if (signalLineDragType.v === null || signalLineDragType.v === 'line') {
                    g.attr({
                        transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]
                    });
                }
                dragTracker(true, dx, dy);
            }
        }

        var start = function (x, y, evt) {
            if (signalOverlayActive.v === true) {
                return
            }
            let buttonPressed = evt.button
            if (buttonPressed !== 0) {
                rightClicked = true
                return // only operate on left clicks. Leave right click to browser's use.
            }
            reportDraggingLayer(layer.layerKey)
            rightClicked = false
            let currentXY_values = currentXY()
            let currentXY2_values = null
            if (layer.layerName === 'line') {
                currentXY2_values = currentXY2()
                let ele = document.getElementById('drawingArea')
                let bbox = ele.getBoundingClientRect()
                let mul = 240 / bbox.width
                let xt = x - bbox.left
                let yt = y - bbox.top

                xt *= mul
                yt *= mul
                xt -= 120
                yt -= 120
                xt -= (window.scrollX * mul)
                yt -= (window.scrollY * mul)
                let distance = Utility.distanceTwoPoints({ x: currentXY_values[0], y: currentXY_values[1] },
                    { x: currentXY2_values[0], y: currentXY2_values[1] })
                let indicator = 'line highlight'
                if (distance > 5) {
                    let distanceFromStart = Utility.distanceTwoPoints({ x: currentXY_values[0], y: currentXY_values[1] },
                        { x: xt, y: yt })
                    let distanceFromEnd = Utility.distanceTwoPoints({ x: currentXY2_values[0], y: currentXY2_values[1] },
                        { x: xt, y: yt })
                    if (distance - distanceFromStart > distance - distanceFromEnd) {
                        if (distanceFromStart <= 2.5) {
                            indicator = 'circle on start'
                        }
                    }
                    else {
                        if (distanceFromEnd <= 2.5) {
                            indicator = 'circle on end'
                        }
                    }
                    // lets say, umm, 10 "distance units" is good for determining if you clicked on an end point or not?
                    if (indicator === 'line highlight') {
                        signalLineDragType.v = 'line'
                        let thicknessInputKey = null
                        let thicknessInput = layer.inputs.find(input => input.named === 'thickness')
                        if (thicknessInput) {
                            thicknessInputKey = thicknessInput.inputKey
                        }
                        if (thicknessInputKey) {
                            let thickness = state.activeLayerValues[layer.layerKey + '_' + thicknessInputKey]
                            if (thickness) {
                                let lg = g.line(Utility.roundFloat(currentXY_values[0], 2), Utility.roundFloat(currentXY_values[1]),
                                    Utility.roundFloat(currentXY2_values[0]), Utility.roundFloat(currentXY2_values[1])).attr({
                                        "stroke": "cyan",
                                        "strokeWidth": thickness + 2,
                                        "strokeLinecap": "round",
                                        "opacity": "0.5"
                                    })
                                setTimeout(() => {
                                    lg.remove()
                                }, 200)
                            }
                        }
                    }
                    if (indicator === 'circle on start') {
                        signalLineDragType.v = 'start'
                        let clickOnStartCircle = g.circle(currentXY_values[0], currentXY_values[1], 5).attr({ stroke: "red", strokeWidth: 1, fill: "none" })
                        setTimeout(() => {
                            clickOnStartCircle.remove()
                        }, 200)
                    }
                    if (indicator === 'circle on end') {
                        signalLineDragType.v = 'end'
                        let clickOnEndCircle = g.circle(currentXY2_values[0], currentXY2_values[1], 5).attr({ stroke: "red", strokeWidth: 1, fill: "none" })
                        setTimeout(() => {
                            clickOnEndCircle.remove()
                        }, 200)
                    }
                }
            }
            cx = currentXY_values[0]
            cy = currentXY_values[1]
            g.data('origTransform', this.transform().local);
            g.attr({ cursor: "grabbing" })
        }
        
        var stop = function () {
            if (signalOverlayActive.v === true) {
                return
            }
            dragTracker(false)
            g.attr({ cursor: "pointer" })
            reportDraggingLayer(-1)
        }

        g.drag(move, start, stop);
    }

    const reportDraggingLayer = (layerKey) => {
        actions.draggingLayer(layerKey)
    }

    // every draw layer has a xy_position control, except for the base layer.
    const currentXY = () => {
        let xyInput = layer.inputs.find(input => input.type === 'xy_position')
        if (xyInput) {
            let xyInputKey = xyInput.inputKey
            let xyStartValues = state.activeLayerValues[layer.layerKey + '_' + xyInputKey]
            return xyStartValues
        }
        return null
    }

    // specifically the line layer, which has two xy_position inputs.
    const currentXY2 = () => {
        let xyInputs = layer.inputs.filter(input => input.type === 'xy_position')
        if (xyInputs.length > 1) {
            let xyInput = xyInputs[1]
            if (xyInput) {
                let xyInputKey = xyInput.inputKey
                let xyStartValues = state.activeLayerValues[layer.layerKey + '_' + xyInputKey]
                return xyStartValues
            }
        }
        return null
    }

    const reportDragged = (cx, cy) => {
        let inputKey = null
        let inputKey2 = null
        cx = Utility.roundFloat(cx, 1)
        cy = Utility.roundFloat(cy, 1)
        let input = layer.inputs.find(li => li.type === 'xy_position')

        inputKey = input.inputKey
        let combinedAlv = layer.layerKey + '_' + inputKey
        let currentVal = state.activeLayerValues[combinedAlv]
        let currentX = currentVal[0]
        let currentY = currentVal[1]
        let newX = currentX
        let newY = currentY
        if (!signalLineDragType.v || signalLineDragType.v === 'line' || signalLineDragType.v === 'start') {
            newX = currentX + cx
            newY = currentY + cy
        }

        let combinedAlv2 = null
        let currentVal2 = null
        let currentX2 = null
        let currentY2 = null
        let newX2 = null
        let newY2 = null
        // line has two xy_position inputs
        if (signalLineDragType.v === 'line' || signalLineDragType.v === 'end') {
            let inputs = layer.inputs.filter(li => li.type === 'xy_position')
            if (inputs.length === 2) {
                inputKey2 = inputs[1].inputKey
                combinedAlv2 = layer.layerKey + '_' + inputKey2
                currentVal2 = state.activeLayerValues[combinedAlv2]
                currentX2 = currentVal2[0]
                currentY2 = currentVal2[1]
                newX2 = currentX2
                newY2 = currentY2
                if (signalLineDragType.v === 'line' || signalLineDragType.v === 'end') {
                    newX2 = currentX2 + cx
                    newY2 = currentY2 + cy
                }
            }
        }

        //dont use state for this. Use a direct fn fed in from layerMenuItem.
        const dragReport = {
            layerKey: layer.layerKey,
            inputKey: inputKey, x: newX, y: newY,
            inputKey2: inputKey2, x2: newX2, y2: newY2,
            lineDragType: signalLineDragType.v,
            lastLayerKeyDrag: layer.layerKey
        }
        dragUpdate(dragReport)
    }

    return (
        <div id={`drawLayer_${layer.layerKey}`} data-name={layer.layerName} className="draw-layer" style={{ "zIndex": layer.layerOrder }}>
            <svg width="100%" height="100%" id={`svgLayer${layer.layerKey}`} />
        </div>
    )
}

export default DrawLayer