import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSignal } from "@preact/signals-react"
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InstallCustomSvg.scss'

const InstallCustomSvg = ({ close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [paperTarget, setPaperTarget] = useState(null)
    const [svgName, setSvgName] = useState(null)
    const [svgId, setSvgId] = useState(null)
    const [svgNameWarning, setSvgNameWarning] = useState(false)
    const [submitErrorMessage, setSubmitErrorMessage] = useState('')
    const [svgLoadedResult, setSvgLoadedResult] = useState(null)
    const [svgModifiedSvg, setSvgModifiedSvg] = useState(null)
    const [svgLoadError, setSvgLoadError] = useState('')
    const [idPrependText, setIdPrependText] = useState('')
    const [svgSuccessfullyInstalled, setSvgSuccessfullyInstalled] = useState(false)
    const [viewBoxArray, setViewBoxArray] = useState('')
    const signalDragEnter = useSignal(false)
    const [addingSvg, setAddingSvg] = useState(null)

    useEffect(() => {
        const _paperTarget = Snap("#svg_target");
        setPaperTarget(_paperTarget)
        setupDragDropArea()
        return () => {
            if (paperTarget) {
                paperTarget.clear()
            }
        };
    }, [])

    const loadFromFileOnChange = evt => {
        let file = evt.target.files[0]
        let testString = file.name.toLowerCase()
        let fileName = file.name
        if (file.type !== 'image/svg+xml') {
            setSvgLoadError('Only svg files can be installed here. The svg filename must end with .svg')
            return
        }
        if (testString.endsWith('.svg')) {
            fileName = testString.substring(0, testString.length - 4)
        }
        fileName = fileName.length > 20 ? fileName.substring(0, 20) : fileName
        if (fileName.length < 2) {
            setSvgLoadError('filename is too short')
            return
        }
        setSvgName(fileName)
        var reader = new FileReader();
        reader.onload = function (event) {
            setSvgLoadedResult(event.target.result)
        }
        reader.readAsText(file)
    }

    const loadFromDragDrop = file => {
        let testString = file.name.toLowerCase()
        let fileName = file.name
        if (file.type !== 'image/svg+xml') {
            setSvgLoadError('Only svg files can be installed here. If you want to install an image, use the install image option.')
            return
        }
        setSvgLoadError('')
        if (testString.endsWith('.svg')) {
            fileName = testString.substring(0, testString.length - 4)
        }
        fileName = fileName.length > 20 ? fileName.substring(0, 20) : fileName
        setSvgName(fileName)
        var reader = new FileReader();
        reader.onload = function (event) {
            setSvgLoadedResult(event.target.result)
        }
        reader.readAsText(file)
    }

    useEffect(() => {
        let testName = svgName
        if (!testName) {
            return
        }
        testName = testName.trim() // remove spaces at start and end
        testName = testName.replace(/\s\s+/g, ' ') // remove multiple embedded spaces
        setSvgId(Utility.safeSvgId(svgName))
        if (testName) {
            if (Utility.checkNameIsAvailable(state.svgs, testName)) {
                setSvgNameWarning(false)
                setSubmitErrorMessage('')
            }
            else {
                setSvgNameWarning(true)
                setSubmitErrorMessage('name is already taken')
            }
        }
        else {
            setSvgNameWarning(false)
            setSubmitErrorMessage('')

        }
    }, [svgName])

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    const extractNativeId = str => {
        let idResult = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            for (let i = 0; i < splitted.length; i++) {
                let testStr = splitted[i]
                if (testStr.startsWith('id=') || testStr.startsWith('ID=')) {
                    let splittedId = testStr.split('=')
                    idResult = splittedId[1].replaceAll('"', '')
                    break
                }
            }
        }

        return idResult
    }

    const extractNativeViewBoxArray = str => {
        // find out if there is a viewBox coming in on this svg.
        let viewBoxString = ''
        let _viewBoxNativeArray = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let beginningViewboxStringIndex = svgTag.indexOf('viewBox="')
            if (beginningViewboxStringIndex > -1) {
                let endingViewboxStringIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 1)
                if ((beginningViewboxStringIndex > -1 &&
                    endingViewboxStringIndex > -1) &&
                    (endingViewboxStringIndex > beginningViewboxStringIndex)) {
                    let viewboxEndIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 10)
                    if (viewboxEndIndex > -1) {
                        viewBoxString = svgTag.substring(beginningViewboxStringIndex + 9, viewboxEndIndex)
                        viewBoxString = viewBoxString.replaceAll(',', ' ')
                        viewBoxString = viewBoxString.replace(/  +/g, ' ');
                        _viewBoxNativeArray = viewBoxString.split(' ').map(n => Number(n))
                        if (!_viewBoxNativeArray ||
                            !Array.isArray(_viewBoxNativeArray) ||
                            _viewBoxNativeArray.length !== 4) {
                            _viewBoxNativeArray = null
                        }
                    }
                }
            }
        }

        return _viewBoxNativeArray
    }

    const extractNativeWidthHeightStrings = str => {
        let nativeWidthString = null
        let nativeHeightString = null

        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            splitted.forEach(str => {
                if (str.startsWith('width=')) {
                    nativeWidthString = str
                }
                if (str.startsWith('height=')) {
                    nativeHeightString = str
                }
            })

        }

        if (nativeWidthString && nativeHeightString) {
            return { widthString: nativeWidthString, heightString: nativeHeightString }
        }
        return null
    }

    useEffect(() => {
        // the method I use to import and manage svgs is to append the incoming svg string data to the snap paper.
        // if there is a imported viewBox, we use that to determine the width height (aspect ratio'd to 200x200) for 
        // the snap paper.
        // Then I see if there is width/height and a viewport stated in the imported svg.
        // I use the width/height of the imported svg to determine the width height of the snap paper, but if there is no
        // imported width/height determined, then I used bbox to get the width/height.

        if (svgLoadedResult) {
            // if (_paper === null) {
            //     _paper = Snap("#importSvg_") 
            //     setImportPaper(_paper)
            // }
            // else {
            paperTarget.clear()
            //}
            if (!svgLoadedResult) {
                setSvgLoadError('There is a problem with the file. Either its empty or its corrupted.')
                return
            }
            setSvgLoadError('')
            let svgString = svgLoadedResult.trim()
            var svgTagCount = (svgString.match(/\<svg/g) || []).length;
            if (svgTagCount > 200) {
                setSvgLoadedResult(null)
                setSvgModifiedSvg(null)
                setSvgLoadError('Cannot import this svg file, since it has excessive embedded svgs in it:' + svgTagCount)
                setViewBoxArray('')
            }
            else {
                svgString = svgString.replace('<?xml version="1.0"?>', '')
                svgString = svgString.trim()
                svgString = svgString.replace(/\s+/g, ' ');
                svgString = svgString.replace(/\t|\n|\r/g, ' ')

                let parsed = Snap.parse(svgString)
                let nativeId = parsed.node.id

                const prependText = 'p' + Utility.randomString(5) + '_'
                setIdPrependText(prependText)
                // if there isn't an id on it, give it one.
                if (nativeId === null || nativeId === undefined || nativeId.length === 0) {
                    svgString = svgString.replace('<svg', '<svg id="' + svgId + '"')
                }
                else {
                    svgString = svgString.replace('id="' + nativeId + '"', 'id="' + svgId + '"')
                }



                let nativeWidthHeightStrings = extractNativeWidthHeightStrings(svgString)
                let nativeWidth = 0
                let nativeHeight = 0
                let vbFromWidthHeightInspection = ''
                if (nativeWidthHeightStrings) {
                    nativeWidth = (nativeWidthHeightStrings.widthString)
                    nativeWidth = nativeWidth.replace(/[^0-9.]/g, '')
                    nativeHeight = (nativeWidthHeightStrings.heightString)
                    nativeHeight = nativeHeight.replace(/[^0-9.]/g, '')
                    nativeWidth = Math.round(nativeWidth)
                    nativeHeight = Math.round(nativeHeight)
                    const viewBoxString = "0 0 " + nativeWidth + " " + nativeHeight
                    vbFromWidthHeightInspection = viewBoxString
                    svgString = svgString.replace(nativeWidthHeightStrings.widthString, '')
                    svgString = svgString.replace(nativeWidthHeightStrings.heightString, '')
                }

                // deploy the incoming svg
                parsed = Snap.parse(svgString)
                paperTarget.append(parsed)



                let nativeViewBox = extractNativeViewBoxArray(svgString)
                if (!nativeViewBox) {
                    let node = paperTarget.select('#' + svgId)
                    if (node) {
                        let bbox = node.getBBox()
                        if (bbox) {
                            // lets try padding a bit since some svgs seem to use shadows or stroke widths that extend beyond the
                            // width and height settings of the svg.
                            bbox.x -= bbox.width * 0.03
                            bbox.y -= bbox.height * 0.03
                            bbox.width += bbox.width * 0.06
                            bbox.height += bbox.height * 0.06
                            bbox.x = Math.round(bbox.x)
                            bbox.y = Math.round(bbox.y)
                            bbox.width = Math.round(bbox.width)
                            bbox.height = Math.round(bbox.height)
                            let replacementViewBox = 'viewBox="' + bbox.x + ' ' + bbox.y + ' ' + bbox.width + ' ' + bbox.height + '"'
                            svgString = svgString.replace('id="' + svgId + '"', replacementViewBox + ' id="' + svgId + '"')
                            paperTarget.clear()
                            parsed = Snap.parse(svgString)
                            paperTarget.append(parsed)
                            replacementViewBox = replacementViewBox.replace('viewBox="', '')
                            replacementViewBox = replacementViewBox.replace('"','')
                            //setViewBoxArray(replacementViewBox)
                        }
                    }
                }
                else {
                    vbFromWidthHeightInspection = vbFromWidthHeightInspection.replace('viewBox="', '')
                    vbFromWidthHeightInspection = vbFromWidthHeightInspection.replace('"','')
                    //setViewBoxArray(vbFromWidthHeightInspection)
                }

                // get ids that are in the svg
                let node2 = paperTarget.select('#' + svgId)
                let foundIds = Utility.distillIdsFromSnapElement(paperTarget.select('#' + svgId))
                let foundHrefs = Utility.distillHrefsFromSnapElement(paperTarget.select('#' + svgId))
                let allIds = [...foundIds, ...foundHrefs]
                allIds = [...new Set(allIds)];
                // // replace all ids with a unique prepend
                svgString = Utility.replaceIdsInSvgString(allIds, svgString, prependText)
                // now clear the target and redeploy
                paperTarget.clear()
                parsed = Snap.parse(svgString)
                paperTarget.append(parsed)
                setSvgModifiedSvg(svgString)
                setSvgLoadError('')
            }
        }
    }, [svgLoadedResult])  // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSvgName = evt => {
        setSvgName(evt.target.value)
        setSubmitErrorMessage('')
        setSvgNameWarning(false)
    }

    const installSvg = evt => {
        evt.preventDefault()
        if (!svgLoadedResult) {
            return
        }
        if (!svgName) {
            return
        }
        let cleanedName = svgName.trim().replace(/\s\s+/g, ' ')
        setSvgName(cleanedName)
        if (!cleanedName) {
            setSvgNameWarning(true)
            setSubmitErrorMessage('Please supply a name for your svg.')
            return
        }
        if (cleanedName !== svgName) {
            setSvgNameWarning(true)
            setSubmitErrorMessage('Please remove excess spaces.')
            return
        }
        // check if name is okey-dokey
        if (!Utility.checkNameIsAvailable(state.svgs, cleanedName)) {
            setSvgNameWarning(true)
            setSubmitErrorMessage('name is already taken. Please choose another.')
            return
        }
        if (cleanedName.match(/[-_)( a-zA-Z0-9]+$/) === null) {
            setSvgNameWarning(true)
            setSubmitErrorMessage('name has invalid characters. Only alpha-numeric, spaces, and - _ ) (')
            return
        }

        if (cleanedName.length < 2) {
            setSvgNameWarning(true)
            setSubmitErrorMessage('please supply a name of at least 2 characters.')
            return
        }

        setSubmitErrorMessage('')
        setSvgNameWarning(false)
        let useKey = state.svgs.reduce((a, b) => Number(a.svgKey) > Number(b.svgKey) ? a : b).svgKey;
        useKey = Number(useKey)
        if (useKey < 2000) {
            useKey = 2000
        }
        else {
            useKey = useKey + 1
        }

        let svgItem = {
            svgKey: useKey,
            svgCode: svgModifiedSvg,
            svgName: cleanedName,
            uniquePrepend: idPrependText,
            viewBox: viewBoxArray
        }
        setAddingSvg(svgItem)

        // the ids in the svg being displayed need to be changed, since the copy of it has been put into the custom svgs layer(s)
        // to avoid clashing ids.
        const repPrependText = 'p' + Utility.randomString(5) + '_'
        let svgString = svgModifiedSvg.replaceAll(idPrependText, repPrependText)
        // now clear the target and redeploy
        paperTarget.clear()
        const parsed = Snap.parse(svgString)
        paperTarget.append(parsed)
    }

    useEffect(() => {
        if (addingSvg) {
            actions.svgsAdd(addingSvg)
        }
    }, [addingSvg])

    useEffect(() => {
        if (state.svgs && addingSvg) {
            let lastSvg = state.svgs[state.svgs.length - 1]
            let newKey = lastSvg.svgKey
            if (svgModifiedSvg) {
                setSvgSuccessfullyInstalled(true)
                // set layer to visible if it is hidden
                let layers = [...state.layers]

                // get new list
                let customSvgLayer = layers.find(lr => lr.layerName === 'custom svgs')
                let parentCustomLayerKey = customSvgLayer.layerKey
                let customSvgLayers = layers.filter(sly => sly.layerKey === parentCustomLayerKey || sly.parentLayerKey === parentCustomLayerKey)
                for (let i = 0; i < customSvgLayers.length; i++) {
                    let cvl = customSvgLayers[i]
                    cvl.layerHidden = 0
                    // add svgKey to layer inputs
                    cvl.inputs = JSON.parse(JSON.stringify(cvl.inputs))
                    let input = cvl.inputs.find(li => li.named === 'svgKey')
                    if (input) {
                        if (input.list.includes(newKey) === false) {
                            input.list.push(newKey)
                            // we need to change ref of inputs so that 
                            // layerMenuItem knows it was updated.
                            cvl.inputs = JSON.parse(JSON.stringify(cvl.inputs))
                        }
                    }
                }

                updateSvgCount()
                actions.layers(layers)
            }
            setAddingSvg(null)

        }
    }, [state.svgs])

    const updateSvgCount = () => {
        const customSvgsLayer = state.layers.find(csl => csl.layerName === 'custom svgs')

        if (customSvgsLayer) {
            let input = customSvgsLayer.inputs.find(csl => csl.named === 'svgKey')
            let _svgs = state.svgs.filter(svg => input.list.includes(svg.svgKey))
            if (_svgs.length === 1) {
                optionalInfo('There is 1 svg')
            }
            if (_svgs.length > 1) {
                optionalInfo('There are ' + _svgs.length + ' svgs')
            }
        }
    }


    const onTryAgain = () => {
        if (paperTarget) {
            paperTarget.clear()
        }
        setSvgName(null)
        setSvgLoadedResult(null)
        setSvgModifiedSvg(null)
        setSvgSuccessfullyInstalled(false)
        setSvgNameWarning(false)
        setSubmitErrorMessage('')
        setViewBoxArray('')
        setSvgLoadError('')
    }

    const handleDragEnter = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        if (signalDragEnter.value === false) {
            signalDragEnter.value = true
        }
    }
    const handleDragLeave = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        signalDragEnter.value = false
    }

    useEffect(() => {
        signalDragEnter.value = false
    }, [state.mouseUp])

    const handleDragOver = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        if (signalDragEnter.value === false) {
            signalDragEnter.value = true
        }
    }
    const handleDrop = evt => {
        evt.preventDefault()
        evt.stopPropagation()
        loadFromDragDrop(evt.dataTransfer.files[0])
        signalDragEnter.value = false
    }
    const setupDragDropArea = () => {
        let dropArea = document.getElementById('dropArea')
        dropArea.addEventListener('dragenter', handleDragEnter, false)
        dropArea.addEventListener('dragleave', handleDragLeave, false)
        dropArea.addEventListener('dragover', handleDragOver, false)
        dropArea.addEventListener('drop', handleDrop, false)
    }

    return (
        <div className="install-custom-svg">
            <div className={svgLoadedResult === null ? 'svg-container' : 'svg-container svg-loaded'}>

                <div className="svg-target">
                    <svg id="svg_target" width="100%" height="100%"></svg>
                </div>

                <div className={signalDragEnter.value ? 'drop-text drag-enter' : 'drop-text'}>
                    <div className={svgLoadedResult ? 'display-none' : ''}>
                        <span>Drop</span>
                        <span>SVG</span>
                        <span>here</span>
                    </div>
                </div>

                <div id="dropArea" />

            </div>
            {svgSuccessfullyInstalled ?
                <div className="success">
                    <div className="success-text">SVG file <span>{svgName}</span> has been successfully installed into the Custom Svgs menu.</div>
                    <div className="success-buttons">
                        <button className="standard-button green" onClick={onTryAgain}>Add another</button>
                        <button className="standard-button red" onClick={close}>Exit</button>
                    </div>
                </div>
                :
                <div className="form-area">
                    <div className="info">
                        <p>
                            You can install svgs into the app through this form.
                        </p>
                        <p>
                            After you successfully install a svg, it will be selectable
                            in the "Custom Svgs" menu option.
                        </p>
                    </div>

                    {svgLoadedResult === null ?
                        <form>
                            <div className="choose">Choose a svg file from your device -</div>
                            <div><input type="file" id="installSvgFile" accept=".svg"
                                onChange={loadFromFileOnChange} /></div>
                            <div className="close">
                                <button className="standard-button red" onClick={close}>Cancel</button>
                            </div>
                            {svgLoadError ? <div className="load-error">{svgLoadError}</div> : null}
                        </form>
                        :
                        <form onSubmit={installSvg}>
                            <div className="line-1">loaded : <span>{svgName}.svg</span></div>
                            <div className="line-2">
                                <div>name:</div>
                                <input type="text" className={svgNameWarning ? 'warning' : ''} value={svgName} onChange={onChangeSvgName} />
                                <button className="standard-button blue">Install</button>
                            </div>
                            <div className="line-3">{submitErrorMessage}</div>
                            <div className="line-4">
                                <button className="standard-button green" onClick={onTryAgain}>Try again</button>
                                <button className="standard-button red" onClick={close}>Exit</button>
                            </div>
                        </form>
                    }
                </div>
            }



        </div>
    );
}
export default InstallCustomSvg
