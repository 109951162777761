import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewFonts.scss'

const ViewFonts = ({ close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [fonts, setFonts] = useState([])
    const [fontSettings, setFontSettings] = useState([])
    const [sampleText, setSampleText] = useState('ABCDEFGHIJKLMNOPQRSTUWXYZabcdefghijklmnopqrstuvwxyz01234567890')
    const [boldFont, setBoldFont] = useState(false)
    const [italicFont, setItalicFont] = useState(false)
    const [fontSize, setFontSize] = useState(22)
    const [fontSizeText, setFontSizeText] = useState('22')
    const [fontSizeWarning, setFontSizeWarning] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(null)
    const [deleteWait, setDeleteWait] = useState(false)
    const [timer1, setTimer1] = useState(false)
    const [timer2, setTimer2] = useState(false)

    const fontsUsedInMessageGenerate = fontName => {
        let message = ''
        let occupiedSlots = state.slots.filter(ss => ss.counterState !== null)
        if (occupiedSlots.length > 0) {
            let foundUsedInSlots = occupiedSlots.filter(ss => ss.counterState.fonts && ss.counterState.fonts.includes(fontName))
            if (foundUsedInSlots.length > 0) {
                message = 'Font is used on sheet at slot'
                if (foundUsedInSlots.length > 1) {
                    message += 's '
                }
                else {
                    message += ' '
                }
                let usedInSlots = foundUsedInSlots.map(found => found.number + 1)
                if (usedInSlots.length > 12) {
                    usedInSlots = [...usedInSlots.slice(0, 10), '...', usedInSlots[usedInSlots.length - 1]]
                }
                message += usedInSlots.join(', ')
                message = message.replace(', ...,', ' ...')
            }
        }

        return message
    }

    useEffect(() => {
        let clientTopFixedBarEle = document.getElementById("clientViewTop")
        if (clientTopFixedBarEle) {
            clientTopFixedBarEle.classList.add('display-none')
        }
        return () => {
            let clientTopFixedBarEle = document.getElementById("clientViewTop")
            if (clientTopFixedBarEle) {
                clientTopFixedBarEle.classList.remove('display-none')
            }
            if (timer1) {
                clearTimeout(timer1)
            }
            if (timer2) {
                clearTimeout(timer2)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const setupLocalFonts = () => {
        let _fonts = state.fonts.filter(font => font.fontFamily !== 'Material Icons')
        setFonts([..._fonts])
    }

    useEffect(() => {
        if (state.fonts.length > 0) {
            setupLocalFonts()
        }
    }, [state.fonts]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fonts.length > 0) {
            if (fonts.length === 0) {
                optionalInfo('There are no fonts installed.')
            }
            if (fonts.length === 1) {
                optionalInfo('There is 1 font installed')
            }
            if (fonts.length > 1) {
                optionalInfo('There are ' + fonts.length + ' fonts installed')
            }
            let _fontSettings = []
            fonts.forEach((dt, index) => {
                if (dt.fontSrc) {
                    let fontSrcLength = Utility.getBytes(dt.fontSrc.length)
                    dt.fontSrc = 'font source is base64 encoded, size: ' + fontSrcLength
                }
                else {
                    dt.fontSrc = '0 bytes'
                }
                let fontsUsedMessage = fontsUsedInMessageGenerate(dt.fontFamily)
                let identifier = dt.fontFamily.replaceAll(' ', '_') + '@' + dt.fontStyle + '@' + dt.fontWeight
                let fontSetting = {
                    identifier,
                    fontUrl: dt.fontUrl,
                    fontStyle: dt.fontStyle,
                    fontWeight: dt.fontWeight,
                    fontFamily: dt.fontFamily,
                    fontIsBold: dt.fontWeight > 500 ? true : false,
                    fontIsItalic: dt.fontStyle === 'italic' ? true : false,
                    fontSize: dt.fontSize ? dt.fontSize : 22,
                    fontId: 'font' + index,
                    fontsUsedMessage
                }
                _fontSettings.push(fontSetting)
                // if (dt.fontWeight > 500) {
                //     setTimeout(() => {
                //         let ele = document.getElementById('boldFont_' + dt.fontFamily.replaceAll(' ', '_') + '@' + dt.fontStyle + '@' + dt.fontWeight)
                //         if (ele) {
                //             ele.checked = true
                //         }
                //     }, 1000)
                // }
                // if (dt.fontStyle === 'italic') {
                //     setTimeout(() => {
                //         let ele = document.getElementById('italicFont_' + dt.fontFamily.replaceAll(' ', '_') + '@' + dt.fontStyle + '@' + dt.fontWeight)
                //         if (ele) {
                //             ele.checked = true
                //         }
                //     }, 1000)
                // }
            })

            if (_fontSettings.length > 0) {
                setFontSettings(_fontSettings)
            }

        }
    }, [fonts]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeBoldFont = evt => {
        setBoldFont(evt.target.checked)
    }

    const changeItalicFont = evt => {
        setItalicFont(evt.target.checked)
    }

    const changeFontSize = evt => {
        setFontSize(evt.target.value)
        setFontSizeText(evt.target.value)
        setFontSizeWarning(false)
    }

    const resetSampleText = () => {
        if (sampleText === 'abcdefghijklmnopqrstuvwxyz01234567890') {
            fontSettings.forEach(fs => {
                let ele = document.getElementById(fs.fontId)
                ele.value = sampleText
            })
        }
        setSampleText('abcdefghijklmnopqrstuvwxyz01234567890')
    }

    useEffect(() => {
        fontSettings.forEach(fs => {
            let ele = document.getElementById(fs.fontId)
            if (ele) {
                ele.value = sampleText
            }
        })
    }, [sampleText]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeFontSizeText = evt => {
        let value = evt.target.value
        let invalidValue = false
        setFontSizeText(value)
        if (Utility.isNumeric(value)) {
            value = Utility.roundFloat(Number(value), 1)
            if (value > 0 && value < 101) {
                setFontSize(value)
            }
            else {
                invalidValue = true
            }
        }
        else {
            invalidValue = true
        }
        setFontSizeWarning(invalidValue)
    }

    const changeSampleText = evt => {
        setSampleText(evt.target.value)
    }

    const textareaOnChange = evt => {
    }

    const tryDecodeURLComponent = (str, maxIterations = 3, iterations = 0) => {
        if (iterations >= maxIterations) {
            return str;
        }

        if (typeof str === 'string' && (str.indexOf('%3D') !== -1 || str.indexOf('%25') !== -1)) {
            iterations++
            tryDecodeURLComponent(str, maxIterations, iterations)
        }


        return decodeURIComponent(str);
    }

    const executeDeleteFont = (fontFamily, fontStyle, fontWeight) => {
        let foundFont = state.fonts.find(sf => sf.fontFamily === fontFamily &&
            sf.fontStyle === fontStyle &&
            sf.fontWeight === fontWeight)
        if (foundFont) {
            let fonts = state.fonts.filter(sf => !(sf.fontFamily === fontFamily &&
                sf.fontStyle === fontStyle &&
                sf.fontWeight === fontWeight))
            console.log('calling actions.font with ', fonts)
            actions.fonts(fonts)
        }
    }

    const deleteFont = evt => {
        let targetId = evt.target.id.replace('delete_', '')
        if (confirmDelete === targetId) {
            let fontToDelete = fontSettings.find(fs => fs.fontId === targetId)
            executeDeleteFont(fontToDelete.fontFamily, fontToDelete.fontStyle, fontToDelete.fontWeight)
            setConfirmDelete(null)
            setDeleteWait(false)

            const linkId = fontToDelete.fontFamily.replaceAll(' ', '_') + '@' + fontToDelete.fontStyle + '@' + fontToDelete.fontWeight
            Utility.deleteFontFromDocument(linkId)
        }
        else {
            setConfirmDelete(targetId)
            setDeleteWait(true)
            if (timer1) {
                clearTimeout(timer1)
            }
            let _timer1 = setTimeout(() => {
                setDeleteWait(false)
            }, 300)
            setTimer1(_timer1)

            if (timer2) {
                clearTimeout(timer2)
            }
            let _timer2 = setTimeout(() => {
                setConfirmDelete(null)
            }, 3000)
            setTimer2(_timer2)

        }

    }

    return (
        <div className="view-fonts">


            <div className="fonts-general-settings">
                <div className="controls">
                    <div className="sample-text">
                        <div>sample text: </div>
                        <input type="text" value={sampleText} onChange={changeSampleText} />
                        <div onClick={resetSampleText}>reset</div>
                    </div>
                    <div className="text-options">
                        <label><input id="viewFontsBold" type="checkbox" name="checkbox" value={boldFont} onChange={changeBoldFont} />Bold</label>
                        <label><input id="viewFontsItalic" type="checkbox" name="checkbox" value={italicFont} onChange={changeItalicFont} />Italic</label>

                        <div className="range-box">
                            <div className="font-size-label">font size:</div> <span>1</span><input id="" step="0.1" type="range" min="1" max="100" value={fontSize} className="slider" onChange={changeFontSize} /><span>100</span>
                        </div>
                        <input type="text" className={fontSizeWarning ? 'warning' : ''} value={fontSizeText} onChange={changeFontSizeText} />
                    </div>
                </div>
            </div>



            <div className="fonts-area">
                <table>
                    <tbody>
                        {fontSettings.map((font, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="font-entry">

                                            <div className="first-line">
                                                <div className="font-item">
                                                    font family: <span>{font.fontFamily}</span>
                                                    style: <span>{font.fontStyle}</span>
                                                    weight: <span>{font.fontWeight}</span>
                                                    {font.fontsUsedMessage ? <span>{font.fontsUsedMessage}</span> : ''}
                                                </div>
                                                {fontSettings.length < 2 ? <div>Cannot delete. You must leave at least 1 font in the app.</div> :

                                                    font.fontsUsedMessage === '' ?
                                                    <div className="delete-block">
                                                        <span className={confirmDelete === font.fontId ? 'confirm-delete' : 'display-none'}>Click delete again to confirm</span>
                                                        <button id={`delete_${font.fontId}`} className={confirmDelete === font.fontId ? 'standard-button red' : 'standard-button yellow'} onClick={deleteWait ? null : deleteFont}>Delete</button>
                                                    </div>
                                                    : 
                                                    <button className="standard-button disabled" onClick={null}>Delete</button>
                                                }
                                            </div>


                                            <div className="source-uri">source url: <span>{tryDecodeURLComponent(font.fontUrl)}</span></div>
                                            <div className="sample">
                                                <textarea id={font.fontId} style={{ fontFamily: "'" + font.fontFamily + "'", fontSize: fontSize + 'px', fontWeight: boldFont ? 'bold' : 'normal', fontStyle: italicFont ? 'italic' : 'normal' }} onChange={textareaOnChange} defaultValue={sampleText}></textarea>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

            </div>
        </div>

    );
}
export default ViewFonts
