import React, { useState, useEffect, useContext, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { useSignal } from "@preact/signals-react"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './ViewApp.scss'

const ViewApp = ({ mode, close, optionalInfo }) => {
    const { state, actions } = useContext(StoreContext)
    const [savedSheets, setSavedSheets] = useState([])
    const [successMessage, setSuccessMessage] = useState({ hash: null, message: { message: '', color: '' } })
    const signalDeleteSheetHash = useSignal(null)
    const signalDeleteSheetTimer = useSignal(null)
    const [startupCheckDone, setStartupCheckDone] = useState(false)
    const [resetDatabaseConfirm, setResetDatabaseConfirm] = useState(false)
    const resetDatabaseTimestamp = useSignal(false)
    const [deletingDatabase, setDeletingDatabase] = useState(false)
    const [resetDatabaseErrorMessage, setResetDatabaseErrorMessage] = useState('')
    const [areYouSureTimer, setAreYouSureTimer] = useState(null)
    const [exportDatabaseFilename, setExportDatabaseFilename] = useState('')
    const [showExportDatabaseForm, setShowExportDatabaseForm] = useState(false)
    const exportDatabaseFilenameRef = useRef(null)
    const [loadWorkspaceFileInvalid, setLoadWorkspaceFileInvalid] = useState('')
    const [loadWorkspaceErrors, setLoadWorkspaceErrors] = useState([])
    const [databaseToRestore, setDatabaseToRestore] = useState(null)

    useEffect(() => {
        return () => {
            if (areYouSureTimer) {
                clearTimeout(areYouSureTimer)
            }
        };
    }, [])

    const clickResetDatabase = () => {
        if (deletingDatabase) {
            return
        }
        if (resetDatabaseConfirm) {
            let timestamp = Math.floor(Date.now() / 100)
            if (resetDatabaseTimestamp.value) {
                let diff = timestamp - resetDatabaseTimestamp.value
                if (diff > 3) { // 300 ms
                    resetDatabaseTimestamp.value = false
                    setDeletingDatabase(true)
                }
            }
            else {
                setResetDatabaseConfirm(false)
            }
        }
        else {
            setResetDatabaseConfirm(true)
            resetDatabaseTimestamp.value = Math.floor(Date.now() / 100)
            if (areYouSureTimer === null) {
                let sureTimer = setTimeout(() => {
                    resetDatabaseTimestamp.value = null
                    setDeletingDatabase(false)
                    setResetDatabaseConfirm(false)
                    setAreYouSureTimer(null)
                }, 5000)
                setAreYouSureTimer(sureTimer)
            }
        }
    }

    useEffect(() => {
        if (deletingDatabase) {
            // check to see if server is reachable
            fetch('https://snapcounter.app/wargalleyservice/', { mode: 'no-cors' }).then(r => {
                actions.restoreDatabase(true)
            })
                .catch(e => {
                    setResetDatabaseErrorMessage('The server is not reachable currently. Cannot reset database at this time.')
                });

        }
    }, [deletingDatabase])

    const clickExportDatabase = () => {
        setShowExportDatabaseForm(true)
    }

    useEffect(() => {
        if (showExportDatabaseForm && exportDatabaseFilenameRef.current) {
            exportDatabaseFilenameRef.current.focus()
        }
    }, [showExportDatabaseForm])

    const packageWorkspace = async () => {
        // need to get font data from dexie cause state doesnt carry it (I think it may eat up too much memory storing gigs of font data.
        // We just retrieve the font data from the dexie store when we need it for particular fonts.
        let counterSideActive = await state.dexie['counterSideActive'].toArray()
        let fonts = await state.dexie['fonts'].toArray()
        let svgs = await state.dexie['svgs'].toArray()
        let activeLayerValues = await state.dexie['activeLayerValues'].toArray()
        let layers = await state.dexie['layers'].toArray()
        let layersOpen = await state.dexie['layersOpen'].toArray()
        let savedCounters = await state.dexie['savedCounters'].toArray()
        let savedSheets = await state.dexie['savedSheets'].toArray()
        let sheetSettings = await state.dexie['sheetSettings'].toArray()
        let slots = await state.dexie['slots'].toArray()
        return {
            counterSideActive,
            activeLayerValues,
            fonts,
            layers,
            layersOpen,
            savedCounters,
            savedSheets,
            sheetSettings,
            slots,
            svgs
        }
    }

    const saveFile = async (packagedWorkspace) => {
        let fileObj = {
            fileName: exportDatabaseFilename,
            app: 'snapCounter',
            type: 'workspace',
            version: 3,
            dateSaved: Utility.currentDate(),
            workspace: packagedWorkspace
        }

        var zip = new JSZip();
        zip.file(exportDatabaseFilename + ".json", JSON.stringify(fileObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                saveAs(content, exportDatabaseFilename + '.zip');
                setExportDatabaseFilename('')
                setShowExportDatabaseForm(false)
            }).catch(function () {
                // setSaveResult({ state: false, message: 'Could not save file.' })
            })

    }

    const exportDatabase = async () => {
        if (exportDatabaseFilename.length > 1 && exportDatabaseFilename.length < 256) {
            let workspace = await packageWorkspace()
            saveFile(workspace)
        }
    }

    const onChangeExportDatabaseFilename = evt => {
        if (evt.target.value.length < 256) {
            setExportDatabaseFilename(evt.target.value)
        }
    }

    const appendDateToText = () => {
        let datetext = Utility.currentDateAndTime(false)
        let newName = exportDatabaseFilename + datetext
        if (newName.length < 256) {
            setExportDatabaseFilename(newName)
        }
    }


    const onChangeLoadFromFile = evt => {
        let file = evt.target.files[0]
        if (!file) {
            return // happens if the user brings up the file selection dialog then closes it.
        }
        let importHasCorrectFormat = true
        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                console.log('filename:', filename)
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                        console.log('imported db data:', data)
                    }
                    catch (e) {
                        setLoadWorkspaceFileInvalid('the file could not be loaded. Is it a zip file?')
                        // setRestoreResult({ state: false, message: 'error loading file' })
                        return
                    }
                    if (data.app === undefined || !data.app.startsWith('snapCounter')) {
                        //setRestoreResult({ state: false, message: 'this file does not appear to be a snapCounter file' })
                        setLoadWorkspaceFileInvalid('this file is not SnapCounter database file.')
                        return
                    }
                    if (!data.type || data.type !== 'workspace') {
                        console.log('this file is not a database database file.')
                        setLoadWorkspaceFileInvalid('this file is not a database workspace file.')
                        return
                    }

                    if (data.app === 'snapCounter' && data.type === 'workspace' && data.version === 3) {
                        console.log('new version database backup...')
                        loadDatabaseBackup(data.workspace)
                    }
                    else {
                        if (data.app === 'snapCouner' && data.type === 'workspace') {
                            console.log('Sorry, this is the old version. Loading the old version is not supported yet.')
                        }
                    }
                })
            })
        })
    }

    const loadDatabaseBackup = stores => {
        let abort = false
        let storesData = [
            { store: "counterSideActive", data: null, count: 0, 'required': true },
            { store: "activeLayerValues", data: null, count: 0, 'required': true },
            { store: "fonts", data: null, count: 0, 'required': true },
            { store: "layers", data: null, count: 0, 'required': true },
            { store: "savedCounters", data: null, count: 0, 'required': false },
            { store: "savedSheets", data: null, count: 0, 'required': false },
            { store: "sheetSettings", data: null, count: 0, 'required': true },
            { store: "slots", data: null, count: 0, 'required': true },
            { store: "svgs", data: null, count: 0, 'required': true }]
        console.log('loadDatabaseBackup:', stores)
        for (const [store, data] of Object.entries(stores)) {
            console.log(store, '-', data.length)
            let entry = storesData.find(sd => sd.store === store)
            if (entry) {
                if (entry.required && data.length === 0) {
                    console.error('file is bad, required data for', store, ' is empty')
                    abort = true
                    break
                }
                entry.data = data
                entry.count = data.length
            }
        }
        if (!abort) {
            storesData = storesData.filter(sd => sd.count > 0)
            console.log('load backup into app:', storesData)
            actions.importDatabase(storesData)
        }
    }

    const onChangeLoadFromFileOld = evt => {
        let file = evt.target.files[0]
        if (!file) {
            return // happens if the user brings up the file selection dialog then closes it.
        }
        let importHasCorrectFormat = true
        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                console.log('filename:', filename)
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                        console.log('imported db data:', data)
                    }
                    catch (e) {
                        setLoadWorkspaceFileInvalid('the file could not be loaded. Is it a zip file?')
                        // setRestoreResult({ state: false, message: 'error loading file' })
                        return
                    }
                    console.log('data.app:', data.app)
                    if (data.app === undefined || !data.app.startsWith('snapCounter')) {
                        //setRestoreResult({ state: false, message: 'this file does not appear to be a snapCounter file' })
                        setLoadWorkspaceFileInvalid('this file is not SnapCounter file.')
                        return
                    }
                    console.log('data.type:', data.type)
                    if (!data.type || data.type !== 'workspace') {
                        console.log('this file is not a database export file.')
                        setLoadWorkspaceFileInvalid('this file is not a database workspace file.')
                        return
                    }
                    let integrityCheck = []
                    if (data.app === 'snapCounter') {
                        console.log('this is the old version.')
                        integrityCheck = [
                            { table: "activeLayerValues", type: "Object", minLength: 1, error: "" },
                            { table: "fonts", type: "Array", minLength: 1, error: "" },
                            { table: "layers", type: "Array", minLength: 14, error: "" },
                            { table: "savedCounters", type: "Object", minLength: 0, error: "" },
                            { table: "savedSheets", type: "Object", minLength: 0, error: "" },
                            { table: "sheetSettings", type: "Object", minLength: 0, error: "" },
                            { table: "slots", type: "Array", minLength: 0, error: "" },
                            { table: "svgs", type: "Array", minLength: 127, error: "" }
                        ]

                        integrityCheck.forEach(ic => {
                            let type = ic.type
                            if (type === 'Array') {
                                if (!Array.isArray(data.workspace[ic.table])) {
                                    ic.error = 'is not an array'
                                }
                                else {
                                    if (data.workspace[ic.table].length < ic.minLength) {
                                        ic.error = "is not of minimum expected length:" + ic.minLength
                                    }
                                }
                            }
                            if (type === 'Object') {
                                if (!Utility.isObjectNotArray(data.workspace[ic.table])) {
                                    ic.error = 'is not an Object'
                                }
                            }
                        })

                        if (data.workspace.layers && Array.isArray(data.workspace.layers)) {
                            let notSpacerInputs = []
                            data.workspace.layers.forEach(dt => {
                                dt.parentLayerKey = -1 // something I missed on original design of schema.
                            })
                            // remove spacers from inputs. Spacers not used in version 3.
                            data.workspace.layers.forEach(layer => {
                                notSpacerInputs = []
                                layer.inputs.forEach(input => {
                                    if (input.named.includes('spacer') || input.inputKey === 5 || input.inputKey === 59 || input.inputKey === 72 || input.type.includes('spacer')) {
                                        ;
                                    }
                                    else {
                                        notSpacerInputs.push(input)
                                    }
                                })
                                layer.inputs = notSpacerInputs
                                layer.layerInputKeys = layer.layerInputKeys.filter(inputKey => inputKey !== 5 && inputKey !== 59 && inputKey !== 72)
                            })
                        }

                        data.workspace.layersOpen = [] // didnt have this store in old version.
                        if (data.workspace.activeLayerValues &&
                            !Array.isArray(data.workspace.activeLayerValues) &&
                            (typeof data.workspace.activeLayerValues === 'object')
                        ) {
                            // change over to dexie version
                            let dexieCompatible = []
                            for (const [combinedKey, value] of Object.entries(data.workspace.activeLayerValues)) {
                                let splitKey = combinedKey.split('_')
                                if (splitKey.length === 2) {  // filter out spacers
                                    if (splitKey[1] !== '5' && splitKey[1] !== '59' && splitKey[1] !== '72') {
                                        dexieCompatible.push({ lik: combinedKey, value })
                                    }
                                }
                            }
                            data.workspace.activeLayerValues = dexieCompatible
                        }

                        if (data.workspace.savedSheets &&
                            !Array.isArray(data.workspace.savedSheets)) {
                            let arrayValues = Object.values(data.workspace.savedSheets)
                            data.workspace.savedSheets = arrayValues
                        }
                        if (data.workspace.savedCounters &&
                            !Array.isArray(data.workspace.savedCounters)) {
                            let arrayValues = Object.values(data.workspace.savedCounters)
                            data.workspace.savedCounters = arrayValues
                        }
                        if (data.workspace.sheetSettings &&
                            !Array.isArray(data.workspace.sheetSettings)) {
                            let sheetSettingsArray = []
                            sheetSettingsArray.push(data.workspace.sheetSettings) // there should only be one.
                            sheetSettingsArray[0].guideType = 'none' // new field for version 3.
                            data.workspace.sheetSettings = sheetSettingsArray
                        }
                        if (data.workspace.svgs.length > 0) {
                            // get the custom svgs, and in the old version they were wrappe ready for svgButton display.
                            // In the new snapCounter, we just store the raw svg, and let the svgButton wrap it for display
                            // in its button. So strip the now unneeded svg width= height= wrapper that is around them.
                            let customSvgs = data.workspace.svgs.filter(svg =>
                                svg.svgKey > 1080 &&
                                !svg.svgCode.startsWith('<svg viewBox="-120, -120, 240, 240">')
                            )
                            // true svgs will have a uniquePrepend with a value. images wrapped in a svg will have blank for uniquePrepend.
                            customSvgs.forEach((svg, index) => {
                                console.log('&&&&&& cgecjc fir uniqyeprepend svg: ', svg)
                                svg.svgCode = updateOldVersionSvg(svg.svgCode, index)
                                if (svg.uniquePrepend === '') {
                                    console.log('this is an image ', svg)
                                    let widths = svg.svgCode.match(/width=".*?"/g)
                                    let heights = svg.svgCode.match(/height=".*?"/g)
                                    if (widths && widths.length > 0 && heights && heights.length > 0) {
                                        let width = widths[0]
                                        let height = heights[0]
                                        svg.svgCode = svg.svgCode.replace(width, 'width="100%"')
                                        svg.svgCode = svg.svgCode.replace(height, 'height="100%"')
                                    }
                                }
                            })

                            // move custom svgs to start at svgKey 2000
                            let nextSvgKey = 2000
                            let customImagesLayer = state.layers.find(sl => sl.layerName === 'custom images')
                            let customImagesLayerKey = customImagesLayer.layerKey
                            let customSvgsLayer = state.layers.find(sl => sl.layerName === 'custom svgs')
                            let customSvgsLayerKey = customSvgsLayer.layerKey


                            // ok apparently the dupe layers are coming in without the parentLayerKey being set, They are at -1.
                            // I figured out a way to correct this by matching layers and dupes by their first input comment - which are unique (just enough)
                            // then after I have this match, I can set the parentLayerKey on the incoming layers correctly.
                            let topLayers = state.layers.filter(sl => sl.parentLayerKey === -1)
                            let stateTopLayersInfo = []
                            topLayers.forEach(tly => {
                                stateTopLayersInfo.push({ layerKey: tly.layerKey, layerName: tly.layerName, firstComment: tly.inputs[0].comment })
                            })
                            data.workspace.layers.forEach(icl => {
                                // remove incoming layers from consideration if they match a top layer directly
                                let matchedTopLayer = state.layers.find(sl => sl.layerKey === icl.layerKey)
                                if (!matchedTopLayer) { // means its a dupe.
                                    let incomingLayerFirstComment = icl.inputs[0].comment
                                    let matchedOnComment = stateTopLayersInfo.find(stli => stli.firstComment === incomingLayerFirstComment)
                                    if (matchedOnComment) {
                                        // set parentLayerKey correctly on incoming layer
                                        icl.parentLayerKey = matchedOnComment.layerKey
                                    }
                                }
                            })

                            let incomingCustomImagesLayers = data.workspace.layers.filter(wkl => wkl.layerKey === customImagesLayerKey || wkl.parentLayerKey === customImagesLayerKey)
                            let incomingCustomSvgsLayers = data.workspace.layers.filter(wkl => wkl.layerKey === customSvgsLayerKey || wkl.parentLayerKey === customSvgsLayerKey)
                            console.log('incomingCustomImagesLayer:', incomingCustomImagesLayers)
                            console.log('incomingCustomSvgsLayer:', incomingCustomSvgsLayers)

                            let bothCustomLayers = [...incomingCustomImagesLayers, ...incomingCustomSvgsLayers]
                            let customRangeImages = data.workspace.svgs.filter(dws => dws.svgKey > 1080 && dws.uniquePrepend === '')
                            let customRangeSvgs = data.workspace.svgs.filter(dws => dws.svgKey > 1080 && dws.uniquePrepend)

                            customRangeImages.forEach(svg => {
                                let oldKey = svg.svgKey
                                let newKey = nextSvgKey
                                console.log('image oldKey:', oldKey, 'newKey:', newKey)
                                nextSvgKey++
                                bothCustomLayers.forEach(ly => {
                                    let svgInputKey = ly.layerActiveRequiredInputKey
                                    let dexieAlvFound = data.workspace.activeLayerValues.find(alv => alv.lik === ly.layerKey + '_' + svgInputKey)
                                    if (dexieAlvFound.value === oldKey) {
                                        dexieAlvFound.value = newKey
                                    }
                                    let input = ly.inputs.find(inp => inp.named === 'svgKey' && inp.valueClass === 'list')
                                    if (input.list.includes(oldKey)) {
                                        input.list = input.list.filter(num => num !== oldKey)
                                        if (input.list.includes(newKey) === false) {
                                            input.list.push(newKey)
                                        }
                                    }
                                })
                                svg.svgKey = newKey
                            })

                            customRangeSvgs.forEach(svg => {
                                let oldKey = svg.svgKey
                                let newKey = nextSvgKey
                                console.log('svg oldKey:', oldKey, 'newKey:', newKey)
                                nextSvgKey++
                                bothCustomLayers.forEach(ly => {
                                    let svgInputKey = ly.layerActiveRequiredInputKey
                                    console.log('is this lik ok?: ' + ly.layerKey + '_' + svgInputKey)
                                    console.log('data.workspace.activeLayerValues:', data.workspace.activeLayerValues)
                                    let dexieAlvFound = data.workspace.activeLayerValues.find(alv => alv.lik === ly.layerKey + '_' + svgInputKey)
                                    console.log('this active value for image layer ', ly.layerKey, ' was pointing at svg:', dexieAlvFound.value)
                                    if (dexieAlvFound.value === oldKey) {
                                        dexieAlvFound.value = newKey
                                    }

                                    let input = ly.inputs.find(inp => inp.named === 'svgKey' && inp.valueClass === 'list')
                                    if (input.list.includes(oldKey)) {
                                        input.list = input.list.filter(num => num !== oldKey)
                                        if (input.list.includes(newKey) === false) {
                                            input.list.push(newKey)
                                        }
                                    }
                                })
                                svg.svgKey = newKey
                            })
                        }




                        // create combined svg field
                        data.workspace.slots.forEach(slot => {
                            // the old version sheet had somewhat different positioning values, so we need to update the
                            // old slot's svg to get it to be positioned correctly.
                            if (slot.counterState) {
                                let updatedSvg = updateOldSlotSvg(slot.svg)
                                slot.counterState.combinedSvg = updatedSvg
                                delete slot.svg
                                if (slot.counterState.layers) {
                                    slot.counterState.layers.forEach(fl => {
                                        delete fl.svg
                                        delete fl.svgIdPrepend
                                    })
                                }

                                // snapCounter 3 expects the svgs to be in the slots as customImageSvgsData and customSvgsData
                                if (slot.counterState.customSvgs && !slot.counterState.customImageSvgsData && !slot.counterState.customSvgsData) {
                                    let customImageSvgsData = []
                                    let customSvgsData = []
                                    slot.counterState.customSvgs.forEach(csvg => {
                                        if (csvg.uniquePrepend) {
                                            csvg.type = 'svg'
                                            customSvgsData.push(csvg)
                                        }
                                        else {
                                            csvg.type = 'image'
                                            customImageSvgsData.push(csvg)
                                        }
                                    })

                                    let combinedArrays = [...customImageSvgsData, ...customSvgsData]
                                    combinedArrays.forEach((svg, index) => {
                                        svg.svgCode = updateOldVersionSvg(svg.svgCode, index)
                                    })

                                    // we need to install any svgs, and/or change key references
                                    //installNeededSvgs(combinedArrays)


                                    slot.counterState.customImageSvgsData = customImageSvgsData
                                    slot.counterState.customSvgsData = customSvgsData

                                    slot.counterState.customImageSvgKeys = []
                                    slot.counterState.customSvgKeys = []
                                    delete slot.counterState.customSvgs
                                }


                                // the alv in slots will be in object format.
                                let alv = slot.counterState.activeLayerValues
                                for (const [key] of Object.entries(alv)) {
                                    if (key.endsWith('_5') || key.endsWith('_59' || key.endsWith('_72'))) {
                                        delete alv[key] // remove the useless deprecated 'spacer' entry
                                    }
                                }
                            }
                        })
                        // still gotta deal with slots and updating each of them with the corrected parentLayerKeys
                        // on duped layers and activeLayerValue changes in svgKeys and the svgKeys that might be in the
                        // slot info.

                    }
                    else {
                        integrityCheck = [
                            { table: "activeLayerValues", type: "Array", minLength: 1, error: "" },
                            { table: "fonts", type: "Array", minLength: 1, error: "" },
                            { table: "layers", type: "Array", minLength: 14, error: "" },
                            { table: "layersOpen", type: "Array", minLength: 0, error: "" },
                            { table: "savedCounters", type: "Array", minLength: 0, error: "" },
                            { table: "savedSheets", type: "Array", minLength: 0, error: "" },
                            { table: "sheetSettings", type: "Array", minLength: 0, error: "" },
                            { table: "slots", type: "Array", minLength: 0, error: "" },
                            { table: "svgs", type: "Array", minLength: 127, error: "" }
                        ]

                        integrityCheck.forEach(ic => {
                            let table = ic.table
                            let type = ic.type
                            if (type === 'Array') {
                                if (!Array.isArray(data.workspace[ic.table])) {
                                    ic.error = 'is not an array'
                                }
                                else {
                                    if (data.workspace[ic.table].length < ic.minLength) {
                                        ic.error = "is not of minimum expected length:" + ic.minLength
                                    }
                                }
                            }
                            if (type === 'Object') {
                                if (!Utility.isObjectNotArray(data.workspace[ic.table])) {
                                    ic.error = 'is not an Object'
                                }
                            }
                        })
                    }



                    let errorsFound = integrityCheck.filter(ic => ic.error !== '')
                    if (errorsFound.length > 0) {
                        setLoadWorkspaceErrors(errorsFound)
                    }
                    else {
                        console.log('workspace load looks ok.')
                        setLoadWorkspaceFileInvalid(false)
                        setLoadWorkspaceErrors([])
                        console.log('commented out for now - setDatabaseToRestore with data:', data)
                        setDatabaseToRestore(data)
                    }
                })
            })

        })
    }

    const updateOldSlotSvg = strOld => {
        console.log('strOld:', strOld)
        // get rid of the start <g tag and the ending /g> tag'
        let startEndG = strOld.indexOf('>')
        let newOld = strOld.substring(startEndG + 1)
        newOld = newOld.substring(0, newOld.length - 4)
        let drawLayerPos = newOld.indexOf('_drawLayer')

        while (drawLayerPos > -1) {
            let viewBoxPos = newOld.indexOf('viewBox=', drawLayerPos)
            let checkNumbersText = newOld.substring(viewBoxPos, viewBoxPos + 30)
            if (checkNumbersText.includes('-120, -120, 240, 240')) {
                newOld = newOld.substring(0, viewBoxPos) + ' width="100%" height="100%" viewBox="-100, -100, 200, 200"' + newOld.substring(viewBoxPos + 30)
            }
            else {
                newOld = newOld.replace('0, 0, 240, 240', '20, 20, 200, 200', viewBoxPos)
            }
            drawLayerPos = newOld.indexOf('_drawLayer', drawLayerPos + 10)
        }
        newOld = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">' + newOld + '</svg>'
        newOld = newOld.replaceAll('class="counter-svg"', '')
        newOld = newOld.replaceAll('style="pointer-events: visiblepainted; cursor: pointer;"', '')

        return newOld
    }

    const updateOldVersionSvg = (strOld, index) => {
        let startSvgTag = strOld.indexOf('<svg ')
        let endSvgTag = strOld.indexOf('>', startSvgTag + 1)
        console.log('startSvgTag:', startSvgTag)
        console.log('endSvgTag:', endSvgTag)
        let firstSvg = strOld.substring(startSvgTag, endSvgTag + startSvgTag + 1)
        console.log('firstSvg:', firstSvg)

        let secondSvgTagPos = strOld.indexOf('<svg ', endSvgTag + startSvgTag + 1)
        let strFromSecondSvg = strOld.substring(secondSvgTagPos)
        let lastClosingSvg = strFromSecondSvg.lastIndexOf('</svg>')
        strFromSecondSvg = strFromSecondSvg.substring(0, lastClosingSvg)
        console.log('secondSvgTag:', strFromSecondSvg)

        /* leave this here in case theres issues with width height
        let paper = Snap('#testSvg' + index);
        let parse = Snap.parse(strFromSecondSvg)
        paper.append(parse)
        let svgSelected = paper.select('svg')
        console.log('svgSelected: ', svgSelected)
        var attrs = svgSelected.attr("viewBox");
        console.log('x:', attrs.x, 'y;', attrs.y, 'width:', attrs.width, 'height:', attrs.height)
        */


        //let updatedSvg = strOld.substring(endSvgTag + 1)
        //console.log('updatedSvg:', updatedSvg)

        // while (drawLayerPos > -1) {
        //     let viewBoxPos = newOld.indexOf('viewBox=', drawLayerPos)
        //     let checkNumbersText = newOld.substring(viewBoxPos, viewBoxPos + 30)
        //     if (checkNumbersText.includes('-120, -120, 240, 240')) {
        //         newOld = newOld.substring(0, viewBoxPos) + ' width="100%" height="100%" viewBox="-100, -100, 200, 200"' + newOld.substring(viewBoxPos + 30)
        //     }
        //     else {
        //         newOld = newOld.replace('0, 0, 240, 240', '20, 20, 200, 200', viewBoxPos)
        //     }
        //     drawLayerPos = newOld.indexOf('_drawLayer', drawLayerPos + 10)
        // }
        // newOld = '<svg id="" xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">' + newOld + '</svg>'

        return strFromSecondSvg
    }

    useEffect(() => {
        if (databaseToRestore) {
            console.log('databaseToRestore:', databaseToRestore)
            //setDatabaseToRestore(null)
        }
    }, [databaseToRestore])

    const onClickLoadFile = evt => {
        setLoadWorkspaceFileInvalid(false)
        setLoadWorkspaceErrors([])
    }

    const onClickExecuteImport = async () => {
        console.log('onClickExecuteImport')
        if (databaseToRestore) {
            console.log('calling actions.importDatabase with ', databaseToRestore.workspace)
            actions.importDatabase(databaseToRestore.workspace)
        }
    }

    const onClickCancelImport = () => {
        setDatabaseToRestore(null)
    }

    return (
        <div className="view-app">


            <div className="entry export">
                <div><button className={showExportDatabaseForm ? 'standard-button disabled' : 'standard-button blue'} onClick={showExportDatabaseForm ? null : clickExportDatabase}>export database</button></div>
                <div className="info">
                    <div className="title">Export entire app database to a file</div>
                    <div className="export-container">
                        <div className={showExportDatabaseForm ? 'comments hide' : 'comments'}>
                            <p>
                                You can save the database, basically the "workspace" of the app, to an external file. This file can then be
                                imported later to restore the workspace.
                            </p>Export
                            <p>
                                This gives you a backup of the current workspace that lets you put everything back the way it was.
                                The file can also be used to reproduce the same workspace on a different instance of the app,
                                such as on a different browser, different computer, or sent to a colleague who can load your
                                workspace into their app instance.
                            </p>
                            <p>
                                This has nothing to do with counters or sheets that were saved to files. Those aren't affected.
                            </p>
                        </div>
                        <div className={showExportDatabaseForm ? 'export-database-form' : 'display-none'}>
                            export database filename: <input ref={exportDatabaseFilenameRef} value={exportDatabaseFilename} onChange={onChangeExportDatabaseFilename} type="text" />
                            <button className={exportDatabaseFilename.length > 1 ? 'standard-button blue' : 'standard-button disabled'} onClick={exportDatabaseFilename.length > 1 ? exportDatabase : null}>submit</button>
                            <button className="standard-button yellow" onClick={() => setShowExportDatabaseForm(false)}>cancel</button>
                            <div className="append-date" onClick={appendDateToText}>click to append date to end of filename</div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="entry import">

                {databaseToRestore ?
                    <div className="import-ready">
                        <p>Import is ready to install:</p>
                        <div className="import-info-box">
                            <p>import name: <span>{databaseToRestore.fileName}</span></p>
                            <p>date created: <span>{databaseToRestore.dateSaved}</span></p>
                            <div className="action-buttons">
                                <button className="standard-button blue" onClick={onClickExecuteImport}>execute import</button>
                                <button className="standard-button yellow" onClick={onClickCancelImport}>cancel</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="import-form">
                        <input type="file" id="sheet_file" accept=".zip" onClick={onClickLoadFile} onChange={onChangeLoadFromFile} />
                        {loadWorkspaceFileInvalid ?
                            <div className="error">{loadWorkspaceFileInvalid}</div>
                            : ''
                        }
                        {loadWorkspaceErrors.length > 0 ?
                            <div className="error">
                                <div>There are problems:</div>
                                {loadWorkspaceErrors.map((err, index) => <div key={index}>{err.table}: {err.error}</div>)}
                            </div>
                            : ''}
                    </div>


                }


                <div className="entry-import-form">
                    <div className="info">
                        <div className="title">Import database from file</div>
                        <div className="comments">
                            <p>
                                You can restore the workspace of this app by importing a database file
                                that you previously saved.
                            </p>
                            <p>
                                This action will remove any customizations you have made to the app as it exists now,
                                including any custom svgs or images, any counters or sheets that are currently
                                saved in the app, and it will replace your sheet settings.
                            </p>
                        </div>
                    </div>
                </div>
            </div>





            <div className="entry reset">

                <div className="entry-reset-buttons">

                    {resetDatabaseErrorMessage ?
                        <div className="error">{resetDatabaseErrorMessage}</div>
                        :
                        <button className={resetDatabaseConfirm ? 'standard-button red' : 'standard-button yellow'} onClick={clickResetDatabase}>
                            <div className="text-options">
                                <span className={resetDatabaseConfirm ? 'disappear' : ''}>reset database</span>
                                <span className={resetDatabaseConfirm ? 'reset-confirm' : 'reset-confirm disappear'}>are you sure?</span>
                            </div>
                        </button>

                    }
                </div>



                <div className="info">
                    <div className="title">Reset database to default settings</div>
                    <div className="comments">
                        <p>
                            If the database on this app has a problem, is corrupted somehow, or if you just want to erase all changes
                            you made to it, you can reset the database to its original state.
                        </p>
                        <p>
                            This will remove all saved counters and sheets that have been saved in the app. I will also all remove
                            any duplicated menu layers, and all custom svgs and images. The sheet settings will also be reverted to defaults.
                        </p>
                        <p>
                            This will not affect any saved counters or saved sheets you may have saved as files to your computer system.
                        </p>
                    </div>
                </div>
            </div>



        </div>
    );
}
export default ViewApp
